import React from 'react'
import { useRouter } from 'next/router'

import { FormField } from 'components/FormControls'
import Tooltip from 'components/Tooltip'
import { FormikProvider, useFormik } from 'formik'

import Button from 'components/Button'
import useBusinessNamePanel, { SelectBusinessNameResultFormAttributes } from './useBusinessNamePanel'

import styles from './BusinessNamePanel.module.css'

type BusinessNamePanelProps = {
  onGoBack: () => void
  onSuccess: (data: SelectBusinessNameResultFormAttributes) => void
  initialFieldValues?: {
    entityName: string
  }
  taxName: string
}

const BusinessNamePanel = ({ onGoBack, onSuccess, taxName, initialFieldValues }: BusinessNamePanelProps) => {
  const router = useRouter()

  const { initialValues, validationSchema, onSubmit } = useBusinessNamePanel({ onSuccess, initialFieldValues })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit
  })

  const { isSubmitting, values, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <div className={styles.formContainer}>
        <h3 className={styles.heading}>Update your business details</h3>
        <FormField
          type="text"
          name="entityName"
          kind="primary"
          title={
            <>
              Business Name*
              <Tooltip iconSize={16}>Business name that your {taxName} is registered under.</Tooltip>
            </>
          }
        />

        <div className={styles.buttonSection}>
          <Button
            icon="arrow-left"
            iconPosition="left"
            onClick={() => (onGoBack ? onGoBack() : router.back())}
            className={styles.backButton}
          />
          <Button
            type="submit"
            kind="dark"
            className={styles.submitButtonContainer}
            disabled={!values.entityName || isSubmitting}
            isSubmitting={isSubmitting}
            icon="arrow-right"
            iconPosition="right"
            onClick={submitForm}>
            Continue
          </Button>
        </div>
      </div>
    </FormikProvider>
  )
}

export default BusinessNamePanel
