import * as Yup from 'yup'
import React, { useMemo } from 'react'
import { FormikHelpers } from 'formik'
import { useLazyQuery } from '@apollo/client'

import {
  CheckAccountDetails,
  CheckAccountDetailsVariables,
  CheckAccountDetails_accountVerification
} from './graphql/__generated__/CheckAccountDetails'
import CHECK_ACCOUNT_DETAILS from './graphql/CheckAccountDetails.graphql'
import { useToast } from 'components/Toast'

export interface SearchForBusinessNumberFormAttributes {
  country: string
  search: string
}
interface UseSearchForBusinessNumberFormProps {
  onSuccess: (
    data: CheckAccountDetails_accountVerification[],
    searchParam: SearchForBusinessNumberFormAttributes
  ) => void
  setFormError: React.Dispatch<React.SetStateAction<string>>
  initialFieldValues?: SearchForBusinessNumberFormAttributes
}

const useSearchForBusinessNumberForm = ({
  onSuccess,
  setFormError,
  initialFieldValues
}: UseSearchForBusinessNumberFormProps) => {
  const [showToast] = useToast()

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      country: Yup.string().required('Required'),
      search: Yup.string().required('Required')
    })
  }, [])

  const initialValues = {
    country: initialFieldValues?.country ?? 'AU',
    search: initialFieldValues?.search ?? ''
  }

  const [checkAccountDetails] = useLazyQuery<CheckAccountDetails, CheckAccountDetailsVariables>(CHECK_ACCOUNT_DETAILS)

  const onSubmit = async (
    values: SearchForBusinessNumberFormAttributes,
    { setSubmitting }: FormikHelpers<SearchForBusinessNumberFormAttributes>
  ) => {
    setFormError('')
    let accountVerificationData
    try {
      accountVerificationData = await checkAccountDetails({ variables: values, fetchPolicy: 'network-only' })
    } catch (e) {
      showToast({ kind: 'error', message: 'Failed to check account details' })
      setSubmitting(false)
      return
    }

    const error =
      accountVerificationData.data?.accountVerification?.length &&
      accountVerificationData.data?.accountVerification[0].error
    if (error || !accountVerificationData.data?.accountVerification?.length) {
      console.error('Verify Buyer Business Details: Error:', error ? error : 'unknown')
      setFormError(error ? error : 'unknown')
    } else {
      setFormError('')
      onSuccess(accountVerificationData.data?.accountVerification ?? [], values)
    }
    setSubmitting(false)
  }

  return {
    validationSchema,
    initialValues,
    onSubmit
  }
}

export default useSearchForBusinessNumberForm
