import * as Yup from 'yup'
import { useMemo } from 'react'
import { FormikHelpers } from 'formik'

import { useToast } from 'components/Toast'

export interface SelectBusinessNumberResultFormAttributes {
  business: string
}

interface UseSelectBusinessNumberResultFormProps {
  onSuccess: (selectedBusiness: SelectBusinessNumberResultFormAttributes) => void
}

const useSelectBusinessNumberResultForm = ({ onSuccess }: UseSelectBusinessNumberResultFormProps) => {
  const [showToast] = useToast()

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      business: Yup.string().required('Required')
    })
  }, [])

  const initialValues = {
    business: ''
  }

  const onSubmit = async (
    values: SelectBusinessNumberResultFormAttributes,
    { setSubmitting }: FormikHelpers<SelectBusinessNumberResultFormAttributes>
  ) => {
    try {
      onSuccess(values)
    } catch (err) {
      console.error('Select Buyer Business: Error:', err)
      showToast({ kind: 'error', message: 'Something went wrong' })
      setSubmitting(false)
    }
  }

  return {
    validationSchema,
    initialValues,
    onSubmit
  }
}

export default useSelectBusinessNumberResultForm
