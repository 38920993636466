import React, { ChangeEvent, ReactNode, useState } from 'react'
import { Field, Form, Formik } from 'formik'

import Icon from 'components/Icon'
import Alert from 'components/Alert'
import Button from 'components/Button'
import { FormInput } from 'components/FormControls'
import { priorityCountryOptions } from 'lib/countries'
import { CheckAccountDetails_accountVerification } from './graphql/__generated__/CheckAccountDetails'
import useSearchForBusinessNumberForm, { SearchForBusinessNumberFormAttributes } from './useSearchForBusinessNumberForm'

import styles from './SearchForBusinessNumberForm.module.css'

type SearchForBusinessNumberFormProps = {
  onSuccess: (
    data: CheckAccountDetails_accountVerification[],
    searchParam: SearchForBusinessNumberFormAttributes
  ) => void
  autoFocusSearch?: boolean
  initialFieldValues?: SearchForBusinessNumberFormAttributes
  renderButton?: ReactNode
  onExit?: () => void
}

const SearchForBusinessNumberForm = ({
  onSuccess,
  autoFocusSearch = true,
  initialFieldValues,
  renderButton,
  onExit
}: SearchForBusinessNumberFormProps) => {
  const [formError, setFormError] = useState<string>('')
  const { initialValues, validationSchema, onSubmit } = useSearchForBusinessNumberForm({
    onSuccess,
    setFormError,
    initialFieldValues
  })

  const isUpdatingDetails = Boolean(initialFieldValues)

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
        const searchTitle =
          values.country === 'AU'
            ? 'Business Number (ABN/ACN) or Business Name'
            : values.country === 'NZ'
            ? 'Business Number (NZBN) or Business Name'
            : values.country === 'GB'
            ? 'Value Added Tax (VAT) Number, typically starts with `GB` prefix'
            : values.country === 'US'
            ? 'Employer Identification Number (EIN)'
            : 'Business Number or Business Name'
        return (
          <Form onSubmit={handleSubmit}>
            <div className={styles.formContainer}>
              <div className={styles.headingContainer}>
                <h3 className={styles.heading}>
                  {values.country === 'US' ? (isUpdatingDetails ? 'Update' : 'Add') : 'Find'} your business details
                </h3>
                {onExit && (
                  <a className={styles.modalClose} onClick={onExit}>
                    <Icon kind="x" size={16} color="currentColor" className={styles.closeIcon} />
                  </a>
                )}
              </div>

              <Field
                name="country"
                title="Country"
                type="select"
                kind="primary"
                options={priorityCountryOptions()}
                component={FormInput}
                onChange={() => setFieldValue('search', '')}
              />
              <Field
                autoComplete="off"
                active={autoFocusSearch}
                title={searchTitle}
                name="search"
                kind="primary"
                component={FormInput}
                className={styles.field}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  const isNumberRegex = /^[0-9]+$/
                  const startsWithGBRegex = /^gb/i

                  // Automatically add 'GB' prefix to value if it doesn't start with GB and is a number
                  if (
                    values.country === 'GB' &&
                    !startsWithGBRegex.test(values.search) &&
                    isNumberRegex.test(event.key)
                  ) {
                    setFieldValue('search', `GB${values.search}`)
                  }
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const search = event.currentTarget.value.toLowerCase()

                  if (values.country === 'GB' && !search.startsWith('gb') && search.length > 2) {
                    setFieldValue('search', `GB${search}`)
                  }
                }}
              />

              {formError && (
                <Alert
                  kind="error"
                  title={
                    formError === 'unknown' ? (
                      <div className={styles.alertTitle}>We couldn’t find any matching business details</div>
                    ) : undefined
                  }
                  className={styles.alert}>
                  {formError === 'unknown' ? (
                    <>
                      We couldn’t verify this {searchTitle}
                      <br />
                      Please check your business details and try again.
                    </>
                  ) : (
                    formError
                  )}
                </Alert>
              )}

              <div className={styles.submitButtonContainer}>
                {renderButton}
                <Button
                  type="submit"
                  kind="dark"
                  disabled={
                    !(values.search.length > 0) ||
                    isSubmitting ||
                    (values.country === 'GB' && values.search.length <= 6)
                  }
                  isSubmitting={isSubmitting}
                  icon="arrow-right"
                  iconPosition="right">
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SearchForBusinessNumberForm
