import React from 'react'
import { FormikProvider, useFormik } from 'formik'

import Button from 'components/Button'
import { useRouter } from 'next/router'
import { FormField } from 'components/FormControls'
import useSelectBusinessNumberResultForm, {
  SelectBusinessNumberResultFormAttributes
} from './useSelectBusinessNumberResultForm'
import { CheckAccountDetails_accountVerification } from '../SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'

import styles from './SelectBusinessNumberResultForm.module.css'

type SelectBusinessNumberResultFormProps = {
  onSuccess: (selectedBusiness: SelectBusinessNumberResultFormAttributes) => void
  onGoBack?: () => void
  businessData: CheckAccountDetails_accountVerification[]
  searchParam: string
}

const SelectBusinessNumberResultForm = ({
  onSuccess,
  onGoBack,
  businessData,
  searchParam
}: SelectBusinessNumberResultFormProps) => {
  const router = useRouter()

  const { initialValues, validationSchema, onSubmit } = useSelectBusinessNumberResultForm({
    onSuccess
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit
  })

  const { isSubmitting, values, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <div className={styles.formContainer}>
        <h3 className={styles.heading}>{`Here’s what we found for "${searchParam}"`}</h3>
        {businessData.length > 1 ? <p className={styles.subheading}>Please select your business</p> : null}

        <FormField
          type="businessNumberResultsCard"
          name="business"
          options={businessData ?? []}
          businessNumberResultsCardProps={{
            className: businessData.length > 1 ? '' : styles.businessCardSection
          }}
        />

        <div className={styles.buttonSection}>
          <Button
            icon="arrow-left"
            iconPosition="left"
            onClick={() => (onGoBack ? onGoBack() : router.back())}
            className={styles.backButton}
          />
          <Button
            type="submit"
            kind="dark"
            className={styles.submitButtonContainer}
            disabled={!values.business || isSubmitting}
            isSubmitting={isSubmitting}
            icon="arrow-right"
            iconPosition="right"
            onClick={submitForm}>
            Continue
          </Button>
        </div>
      </div>
    </FormikProvider>
  )
}

export default SelectBusinessNumberResultForm
