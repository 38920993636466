import React from 'react'
import { useFormikContext } from 'formik'

import { Fieldset, FormField } from 'components/FormControls'
import { FieldsetProps } from 'components/FormControls/Fieldset'
import { AccountDetailsFormArguments } from 'modules/buyer-hub/account/components/AccountDetailsForm/useAccountDetailsForm'

interface SocialMediaDetailsFieldsProps extends FieldsetProps {
  className?: string
}

const SocialMediaDetailsFields = ({ className, subtitle }: SocialMediaDetailsFieldsProps) => {
  const { setFieldValue } = useFormikContext<AccountDetailsFormArguments>()
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/

  const fixWebsiteUrl = (url: string) => {
    let result = url.trim().toLowerCase()

    if (urlRegex.test(result) && !/^https?:\/\//i.test(result)) {
      result = `https://${result}`
    }

    setFieldValue('socialMediaDetailsAttributes.websiteUrl', result)
  }

  const webifyFbUrlOnChange = (url: string) => {
    const regex = /(https?:\/\/m\.|m\.)(fb|facebook)\./

    let result = ''

    if (regex.test(url)) {
      result = url.replace(regex, 'https://www.facebook.')
    } else {
      result = url
    }

    setFieldValue('socialMediaDetailsAttributes.facebookUrl', result)
  }

  const webifyFbUrlOnBlur = (url: string) => {
    let result = url.trim().toLowerCase()

    // Check if the URL is not blank
    if (result !== '' && urlRegex.test(result)) {
      // Check if "https://" is missing and add it
      if (!result.startsWith('https://') && !result.startsWith('http://')) {
        result = `https://${result}`
      } else if (result.startsWith('http://')) {
        result = result.replace('http://', 'https://')
      }

      // Replace "fb.com" with "facebook.com"
      result = result.replace('fb.com', 'facebook.com')

      // Check if "www" is missing and add it
      if (!result.includes('www.')) {
        result = result.replace('://m.', '://www.')
        result = result.replace('://', '://www.')
      }
    }

    setFieldValue('socialMediaDetailsAttributes.facebookUrl', result)
  }

  return (
    <Fieldset title="Website and Social Media" className={className} subtitle={subtitle}>
      <FormField
        type="text"
        name="socialMediaDetailsAttributes.websiteUrl"
        kind="primary"
        title="Website"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => fixWebsiteUrl(e.target.value)}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => fixWebsiteUrl(e.target.value)}
      />
      <FormField
        type="text"
        name="socialMediaDetailsAttributes.instagramUsername"
        kind="primary"
        title="Instagram username"
        iconLeft="instagram"
      />
      <FormField
        type="text"
        name="socialMediaDetailsAttributes.twitterUsername"
        kind="primary"
        title="Twitter username"
        iconLeft="twitter"
      />
      <FormField
        type="text"
        name="socialMediaDetailsAttributes.facebookUrl"
        kind="primary"
        title="Facebook Page"
        iconLeft="facebook"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => webifyFbUrlOnChange(e.target.value)}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => webifyFbUrlOnBlur(e.target.value)}
      />
    </Fieldset>
  )
}

export default SocialMediaDetailsFields
