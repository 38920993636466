import * as Yup from 'yup'
import { FormikHelpers } from 'formik'

import { useToast } from 'components/Toast'

export type SelectBusinessNameResultFormAttributes = {
  entityName: string
}

type UseBusinessNamePanelProps = {
  onSuccess: (selectedBusiness: SelectBusinessNameResultFormAttributes) => void
  initialFieldValues?: {
    entityName: string
  }
}

const useBusinessNamePanel = ({ onSuccess, initialFieldValues }: UseBusinessNamePanelProps) => {
  const [showToast] = useToast()

  const initialValues = {
    entityName: initialFieldValues?.entityName || ''
  }

  const validationSchema = Yup.object().shape({
    entityName: Yup.string().required()
  })

  const onSubmit = async (
    values: SelectBusinessNameResultFormAttributes,
    { setSubmitting }: FormikHelpers<SelectBusinessNameResultFormAttributes>
  ) => {
    try {
      onSuccess(values)
    } catch (err) {
      console.error('Select Buyer Business Name: Error:', err)
      showToast({ kind: 'error', message: 'Something went wrong' })
      setSubmitting(false)
    }
  }

  return {
    initialValues,
    validationSchema,
    onSubmit
  }
}

export default useBusinessNamePanel
